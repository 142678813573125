import { template as template_a490098a69734397b4f32e857c67d634 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a490098a69734397b4f32e857c67d634(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
